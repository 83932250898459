@font-face {
    font-family: "aAtmospheric";
    src: url('../../fonts/aAtmospheric.otf');
    src: url('../../fonts/aAtmospheric.ttf') ;
        
  }
  @font-face {
    font-family: "BebasNeue";
    src: url('../../fonts/BebasNeue-Regular.ttf');
        
  }

a{
    text-decoration: none;
    color: white;
}
.menubar-container div.active {
    color:#F07C00
} 
.hambhurger-menubar-container{
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}
.hambhurger-menubar-container div{
    padding: 1rem;
}
.hambhurger-menubar-container div.active {
    color: #F07C00;
}
.menu-bar{
    display: none;
    position: absolute;
    
    top: 0%;
   width: 100vw;
   background-color: #111111eb;}   
  .hambhurger-socialnav-container{
    color: white;
  }
.home-main-container{
    background-color: #252525;
    background: url("/src/image/backgroundlaptop.webp");
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.navbaricon{
    width: 40%;
}
.navbaricon-container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 1.5rem 1.5rem 3rem;
    width: 30vw;
    background-color:rgba(0, 0, 0, 0.872);
    clip-path: polygon(0 0, 100% 0%, 90% 100%, 0% 100%);
}
.navbaricon-container div{
   
   display: flex;
   justify-content:flex-end;
   padding-right: 6rem;


}
.navbaricon-main-container{

    height: max-content;
}
.navbar-main-container{
    
    background-color:rgb(0 0 0 / 69%);
    width: 100vw;
    display: flex;
    flex-direction: row;
    position: fixed;   
    z-index:1;
    top: 0;

}
.navflex{
    width:70vw;
    display: flex;
    flex-direction: column;
}
.burger-menu{
    display: none;
}
.navbar-main-container{
    margin-right: 138px;
}
.socialnav-main-container{
    width: 100%;
}
.socialnav-container{
    width: 90%;
justify-content: flex-end;   
display: flex;
    flex-direction: row;
}
.hambhurger-socialnav-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;   

}
.menubar-container{
    font-family: "BebasNeue", sans-serif;
    margin-left: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.menubar-container div {
    padding: 0rem 2rem;
    color: white;
}
.socialnav-container a{
    color: white;
    clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);

}
.home-container-reg:hover{
    cursor: pointer;
}
.socialnav-container-mobile a{
    color: white;
    margin: 1%;
    clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);

}
.socialnav-container :hover{
    background-color:#F07C00;
    cursor: pointer;

}
.socialnav-container-mobile :hover{
    background-color:#F07C00;
    cursor: pointer;

}
.menubar-container :hover{
    color:#F07C00;
    cursor: pointer;
 
}
.hamburger-menubar-container :hover{
    color:#F07C00;
    cursor: pointer;
 
}
.hamburger-menubar-container{
    padding: 0.5rem;
    color: white;
    font-family: "gontserrat";
    font-size: small;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.hamburger-menubar-container div {
    padding: 3%;
}
.home-container{
    margin-right: 145px;
    height: 124%;
    padding-left: 45rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    align-content: space-around;
    color: white;
    flex-wrap: wrap;
}
.home-container-launchtxt{
    font-family: "aAtmospheric";
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5rem 0rem 1.1rem 0rem;    background-color:#f07c006d;
    font-size: 30pt;
    padding: 1rem   ;
}
.home-container-lggym h1{
    margin: 0%;
    font-weight: 300;
    font-family: "gontserrat", sans-serif;
    font-size: 15pt;
}
.home-container-reg{
    margin-right: 2px;
    display: flex;
    font-weight: bold;
    font-family: "gontserrat", sans-serif;
    font-size: small;
    align-items: center;
    color: black;
    width: 164px;
    height: 38px;
    background-color:#ffffff;
    outline: 2px solid #F07C00;
    outline-offset: 3px;
}
.home-container-reg2{
    color: white;
    width: 80%;
    padding-right: 10px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #F07C00;
   
    clip-path: polygon(0 0, 100% 0%, 92% 100%, 0% 100%);
}
.home-container-icon{
    margin: 4px;
    font-size:x-large;
    width: 20%;
    font-weight: 300;
    color: #252525;
}
.hide {
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;
  }
  
  .show {
    transform: translateY(0);
    transition: transform 0.3s ease-in-out;
  }
  @keyframes rotateAndZoomIn {
    0% {
        transform: perspective(400px) rotateY(90deg);
        opacity: 0;
      }
      100% {
        transform: perspective(400px) rotateY(0deg);
        opacity: 1;
      }
    }
    
  
  .circles-in{
    animation: rotateAndZoomIn 1.5s ease ;
  }
  .socialnav-container-mobile{
    display: none;
  }
.hambhurger-menubar-container{
    font-family: "BebasNeue", sans-serif;
}

  @media (min-width: 320px) and (max-width: 480px) {
    .menu-bar{
        display: block;
    }
    .home-container-reg2{
        justify-content: center;
    }

    .socialnav-container-mobile{
        display: flex;
        flex-direction: row;
        position: absolute;
        width: 100vw;
        color: white;
      justify-content: flex-end;
        background-color: #00000062;
    }
    
    .home-main-container{
        background-size: 100% 100%;
        background-image: url('../../image//backgr.webp');
    }
    .navbaricon-container{
        display: flex;
        clip-path: none;
        width: auto;
        justify-content: center;
        padding: 1rem;
    }    
    .burger-menu {
        display: block;
        position: absolute;
        z-index: 1;
       
        left: 2%;
        top: 1%; 
      }
    .home-container-launchtxt{
        font-size: 15pt;
    }
    .home-container-lggym h1{
        font-size: medium;
    }
  .navbar-main-container{
    display: none;
  }
  .home-container{
    margin-right: 0rem;
    flex-wrap: nowrap;
    padding-left: 0;
    height: 140%;
    align-items: center;
    justify-content: center;
  }
  .home-container-icon{
    width: 15%;
  }
  .home-container-reg{
    width: 130px;
    font-size: x-small;
  }
  .burger-menu{
    color: #F07C00;
  }
    .navbaricon{
        width: 150px;
    }
    .navbaricon-container{
        clip-path: none;
        width: auto;
    }
    .hambhurger-socialnav-container{
        background-color: #000000ad;
    }
    .home-container-launchtxt{
        margin: 1rem 0rem 1.5rem 0rem;
    }
    
  }
  @media (min-width: 481px) and (max-width: 767px) {
    .home-container-launchtxt{
        font-size: 17pt;
    }
    .navbaricon-container{
        padding: 1.5rem 1.5rem 1.5rem 1.2rem;   
    }
    .home-main-container{
        background-image: url('../../image//backgroundtab.png');
        background-size: 100% 100%;
    }
      
      .home-container{
          padding-left :2rem;
          margin-right: 0px;
          height: 132%;
          align-items: center;
        }
        .menubar-container div{
            font-size: x-small;
            padding:0 10px 0 0 ;
        }
        .menubar-container{
            font-family: "BebasNeue", sans-serif;
            margin: 0%;
        }
        .navbaricon-container div{
            justify-content: flex-start;
        }
        .navbaricon{
            width: 120px;
        }
        .fa-xl{
            font-size: 15px;
        }
    }
    @media (min-width: 767px) and (max-width: 1024px) and (orientation: landscape) {
    
      .home-main-container{
          background-image: url('../../image//backgroundtabport.png');
          background-size: 100% 100%;
      }
      
    }
  @media (min-width: 768px) and (max-width: 1024px) {
    .home-main-container {
        background-image: url('../../image/backgroundtab.png');
        background-size: 100% 100%;
        height: 100vh;
        background-position: center;
    }
    .home-container-launchtxt{
        font-size: 19pt;
    }
  .home-container-reg{
    font-size: x-small;
    width: 142px;
  }
    .home-container{
        height: 117%;
        padding: 0%;
        margin: 0%;
        padding-left: 20rem;
     }
        .menubar-container div{
            font-size: medium;
        padding:0 30px 0 0 ;
        }
        .menubar-container{
            margin: 0%;
        }
        .navbaricon-container div{
            justify-content: flex-start;
        }
        .navbaricon{
            width: 140px;
        }
        .fa-xl{
            font-size: 15px;
        }
    
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
  
   
    .home-main-container {
        background-image: url('../../image/backgroundlaptop.png');
        background-size: 100% 100%;
        height: 100vh;
        background-position: center;
    }
    
    .home-container{
        height: 110%;
        margin: 0%;
        padding: 0%;
        padding-left: 32rem;
    }
    .home-container-launchtxt{
        font-size: 30pt;
    }
    .menubar-container{
            margin-left: 1rem;
            margin-top: 0.5rem;
    }
    .menubar-container div{
        padding: 0 1.5rem;
    }
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    .home-container-launchtxt{
        font-size: 27pt;
    }
  
   .home-main-container{
        background-size: 100vw 100vh;
    }
  }@media (min-width: 1281px) {
  
    .home-main-container{
        background-size:100% 100vh;
    }
    
    
  }
  @media only screen and (min-height: 1440px){
    .home-main-container{
        object-fit: cover;
   
    }
   
    .home-container{
        height: 111%;
    }
  }
  @media (min-width: 1281px) {
    .home-container-reg{
        margin-right: 3px;
    }
    .home-container-launchtxt {
        font-size: 34pt;
        padding: 2rem 1rem ;
    }
    .home-container{
        margin-right: 90px;        height: 118.5%;
        padding-left: 44rem;
    }
  }
  /* conssistantly and word revese 1st borde 3 border 4screen image space  */