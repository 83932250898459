
@font-face {
    font-family: "aAtmospheric";
    src: url('../../fonts/aAtmospheric.otf');
    src: url('../../fonts/aAtmospheric.ttf') ;
        
  }
.membership-main-container{
    background-image: url("/src//image/thirdbg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 100px 120px 137px 120px;
}
.membership-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.membership-heading{
    font-size: large;
    font-family: "aAtmospheric";
    color: white;
}
.membership-subhead{
  text-align: center;
   font-family: "gontserrat", sans-serif;
    color: white;
    font-weight: 500;
    padding-bottom: 2.4rem;
}
.membership-button:hover{
cursor: pointer;
}
.membership-button{
  position: relative;
    font-family: "gontserrat", sans-serif;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: 241px;
    height: 55px;
    background-color: #F07C00;

}
.membership-button-container{
    outline: 2px solid #ffffff;
    outline-offset: 3px;    
}
.slide-out-left {
    animation: slideOutLeft 0.5s ease ;
  }
  
  .slide-out-top {
    animation: slideOutTop 0.5s ease forwards;
  }
  
  .slide-out-bottom {
    animation: slideOutBottom 0.5s ease forwards ;
  }
  
  .slide-out-right {
    animation: slideOutRight 0.5s ease ;
  }
  
  /* Keyframe animations */
  @keyframes slideOutLeft {
    from {
      transform: translateX(0);
      opacity: 1;
    }
    to {
      transform: translateX(-100%);
      visibility: hidden;    }
  }
  
  @keyframes slideOutTop {
    from {
      transform: translateY(0);
      opacity: 1;
    }
    to {
      transform: translateY(-100%);
      visibility: hidden;    }
  }
  
  @keyframes slideOutBottom {
    from {
      transform: translateY(0);
      opacity: 1;
    }
    to {
      transform: translateY(100%);
      visibility: hidden;    }
  }
  
  @keyframes slideOutRight {
    from {
      transform: translateX(0);
      opacity: 1;
    }
    to {
      transform: translateX(100%);
      visibility: hidden;    }
  }
  

@keyframes slideInLeft {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slideInRight {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes slideInTop {
    from {
      transform: translateY(-100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slideInBottom {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  .slide-left {
    animation: slideInLeft 1s ease ;
  }
  
  .slide-right {
    animation: slideInRight 1s ease ;
  }
  
  .slide-top {
    animation: slideInTop 1s ease ;
  }
  
  .slide-bottom {
    animation: slideInBottom 1s ease ;
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .fade-in {
    animation: fadeIn 3.5s ease ;
  }
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  .fade-out {
    animation: fadeOut 0.5s ease ;
  }
    .dropdown-content {
      top: 111%;
      position: absolute;
    }
   .dropdown-buttons:hover{
    cursor: pointer;
   }
   
    .dropdown-buttons{
      margin-bottom: 1px;
      border: #ffffff 1px solid ;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-family: "gontserrat", sans-serif;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: 241px;
    height: 44px;
    background-color: #f07c00;
    }
@media (min-width: 320px) and (max-width: 480px) {
  
.membership-main-container{
    padding: 75px 0 ;
}
    .membership-heading{
        font-size: 5pt;
    }
    .membership-subhead{
      padding-bottom: 1rem;
    }
    .membership-button{
        width: 150px;
        height: 25px;
        font-size: x-small;
    }
    .dropdown-buttons{
      width: 150px;
      height: 25px;
      font-size: x-small;
    }
    .membership-subhead{
        font-size: x-small;
    }
  }
  @media (min-width: 481px) and (max-width: 767px) {
  
.membership-main-container{
    padding: 40px 0 ;
}
    .membership-heading{
        font-size: 7pt;
    }
    .membership-button{
        width: 150px;
        height: 25px;
        font-size: x-small;
    }
    .membership-subhead{
      padding-bottom: 1.2rem;
        font-size: x-small;
    }
    
  }
  @media (min-width: 768px) and (max-width: 1024px) {
  
    .membership-main-container{
        padding: 80px 0 ;
    }
        .membership-heading{
            font-size: 8pt;
        }
        .membership-button{
            width: 150px;
            height: 25px;
            font-size: x-small;
        }
        .dropdown-buttons{
          width: 150px;
          height: 25px;
          font-size: x-small;
        }
        .membership-subhead{
          padding-bottom: 1.2rem;
            font-size: x-small;
        }
    
    
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
  
    .membership-heading{
        font-size: small;
    }
    
  }