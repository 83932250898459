@font-face {
    font-family: "aAtmospheric";
    src: url('../../fonts/aAtmospheric.otf');
    src: url('../../fonts/aAtmospheric.ttf') ;
        
  }

/* Center the loading content and make it responsive */
.loading-container {
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
  }
  
  /* Container for the image and text */
  .loading-content {
    
    text-align: center;
  }
  
  /* Style for the image */
  .loading-image {
    width: max-content; /* Adjust size as needed */
    height: 40vh; /* Adjust size as needed */
  }
  
  /* Style for the loading text */
  .loading-text {
    margin-top: -27px;
    font-family: "aAtmospheric";
    font-weight:200;
    font-size: 39px; /* Adjust size as needed */
    color:#000000; /* Dark grey text color */

  }
  
  
  /* Responsive adjustments */
  @media (max-width: 600px) {
    .loading-image {
      width: max-content;
      height: 15vh;
    }
  
    .loading-text {
        margin-top: -6px;
      font-size: 10px;
    }
  }
  