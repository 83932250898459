@font-face {
  font-family: "aAtmospheric";
  src: url('../../fonts/aAtmospheric.otf');
  src: url('../../fonts/aAtmospheric.ttf') ;
      
}

.amenities-heading-container{
  width: 100%;
  background-color: #2d2d2d;
  display: flex;
  flex-direction: row;
}
.amenities-main-container{
  background-color: #2d2d2d;

}
.amenities-title{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.amenities-carousels{
  width: 50vw;
  display: flex;
}
.amenities-title div{
color: white;
   font-family: "aAtmospheric";
   font-size: 2em;
   background-color: #F07C00;
   width: 30vw;
   padding: 2rem 6rem 2rem 0rem;
   text-align: end;
   clip-path: polygon(0 0, 100% 0%, 75% 100%, 0 100%);
}
.carousel {
  display: flex;
  align-items: end;
}

/* .slide {
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  font-size: small;
  color: #a6a6a6;
  font-family: "montserrat";
  overflow: hidden;
}
 */
.carousel-button-container{
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}


/* CSS */
.prev-button {
margin: 10px;
padding: 6px 16px;
text-align: center;
text-transform: uppercase;
transition: 0.5s;
background-size: 200% auto;
color: white;
border-radius: 10px;
display: block;
border: 0px;
font-weight: 700;
background-color: #F07C00;
box-shadow: 0px 0px 14px -7px #f09819;
cursor: pointer;
user-select: none;
-webkit-user-select: none;
touch-action: manipulation;
}

.prev-button:hover {
background-position: right center;
/* change the direction of the change here */
color: #fff;
text-decoration: none;
}

.prev-button:active {
transform: scale(0.95);
}

.prev-button {
  margin: 10px;
  padding: 6px 16px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  display: block;
  border: 0px;
  font-weight: 700;
  background-color: #F07C00;
  box-shadow: 0px 0px 14px -7px #f09819;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.prev-button:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.prev-button:active {
  transform: scale(0.95);
}
  
.amenities-image:hover{
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.886); 
}
.amenities-image{
transition: box-shadow 0.3s ease; 
}
/* CSS */
.next-button {
  margin: 10px;
  padding: 6px 16px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: rgb(0, 0, 0);
  border-radius: 10px;
  display: block;
  border: 0px;
  font-weight: 700;
  background-color: #eeeeee;
  box-shadow: 0px 0px 14px -7px #e4e2df;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.next-button:hover {
  background-position: right center;
  color: #000000;
  text-decoration: none;
}

.next-button:active {
  transform: scale(0.95);
}
  
.next-button {
    margin: 10px;
    padding: 6px 16px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: rgb(0, 0, 0);
    border-radius: 10px;
    display: block;
    border: 0px;
    font-weight: 700;
    background-color: #dfdfdf;
    box-shadow: 0px 0px 14px -7px #f09819;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .next-button:hover {
    background-position: right center;
    color: #000000;
    text-decoration: none;
  }
  
  .next-button:active {
    transform: scale(0.95);
  }
    
  
  .amenities-image-container {
    
      display: none;
      flex-wrap: wrap;
    }
    
    .amenities-image-row {
      display: flex;
      width: 100%;
    }
    
    .amenities-image {
      flex: 1;
      position: relative; 
    }
    
    .image-container {
      position: relative;
      width: 100%;
      height: 200px; 
    }
    
    .image {
      background-size: cover;
      background-position: center;
      width: 100%;
      height: 100%;
      filter: grayscale(100%); 
      transition: filter 0.3s ease;
    }
    
    .image:hover {
      filter: none; 
    }
    
    .info {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      align-items: end;
      justify-content: end;
      position: absolute;
      top: 0;
      right: -100%;
      height: 100%;
      width: 65%;
      background-color: #f07c00cf;
      color: #fff;
      box-sizing: border-box;
      display: none;
      clip-path: polygon(40% 0, 100% 0, 100% 100%, 0 100%);
      pointer-events: none; /* Disable pointer events to allow hovering through the .info element */
    }
    
    .amenities-image:hover .info,
    .info:hover {
      display: flex;
      right: 0;
    }
    
    
    
    
    .info-heading {
      font-weight: bold;
      margin: 5px 0px 5px 5px; /* Add spacing between heading and text */
    }
    
    .info-text {
      text-align: end;
      margin: 5px 0px 5px 5px; /* Add spacing between heading and text */
    }
    @keyframes flipRight {
      0% {
        transform: perspective(400px) rotateY(-90deg);
        opacity: 0;
      }
      100% {
        transform: perspective(400px) rotateY(0deg);
        opacity: 1;
      }
    }
    
    .text-animation {
      animation: flipRight 1s ease ;
    }


    
    @media (min-width: 320px) and (max-width: 480px) {
      .amenities-mob-title{
        display: flex;
        justify-content: center;
        align-items: center;
      }
.amenities-title div{
  padding: 2rem 0;

}
      /* .slide{
        padding: 1rem;
      } */
      .carousel-button-container{
        padding: 1rem;
      }
      .amenities-image-container{
        display: flex;
          flex-direction: column;
      }
      .amenities-image-row{
        flex-direction: column;
      }
      .amenities-title div{
          clip-path:none;
        width: -webkit-fill-available;
      }
      .amenities-heading-container{
        flex-direction: column;
      }
      .amenities-carousels{
        flex-direction: column;
        width: -webkit-fill-available ;
      }
      .carousel{
        flex-direction: column;
        justify-content: center;
        align-items: center;

      }
      .info-text{
        font-size: xx-small;
      }
      
    }
    @media (min-width: 481px) and (max-width: 767px) {

      /* .slide{
        padding: 1rem;
      } */
      .carousel-button-container{
        padding: 1rem;
      }
      .amenities-image-container{
        display: flex;
          flex-direction: column;
      }
      .amenities-image-row{
        flex-direction: column;
      }
      .amenities-title div{
          clip-path:none;
        width: -webkit-fill-available;
      }
      .amenities-heading-container{
        flex-direction: column;
      }
      .amenities-carousels{
        flex-direction: column;
        width: -webkit-fill-available ;
      }
      .carousel{
        flex-direction: column;
        justify-content: center;
        align-items: center;

      }
      .info-text{
        font-size: xx-small;
      }
      
      
    }
    @media (min-width: 768px) and (max-width: 1024px) {
      .amenities-image-container{
        display: flex;
      }

      .amenities-carousels{
        width: max-content;
      }
      .amenities-image-row{
        flex-wrap: wrap;
      }
      .amenities-image{
        flex: 0 0 50%;
      }
      
    }